<template>
  <s-feedback
    :icon-class="['iconfont', 'icon-empty-history']"
    class="recommend__empty-record-list"
  >
    <img
      slot="icon"
      class="recommend__empty-record-icon"
      :src="IMG_LINK['error_img']"
      alt="error_img"
    />
    <div slot="content">
      <p class="recommend__empty-record-sub">
        {{ language.SHEIN_KEY_PWA_21416 }}
      </p>
      <p class="recommend__empty-record-desc">
        {{ language.SHEIN_KEY_PWA_18579 }}
      </p>
      <p class="recommend__empty-record-btn">
        <s-button
          :type="['primary', 'H72PX']"
          :width="4"
          block
          @click="reRequest"
        >
          {{ language.SHEIN_KEY_PWA_18128 }}
        </s-button>
      </p>
    </div>
  </s-feedback>
</template>
<script>
import { Feedback } from '@shein/sui-mobile'
let { IMG_LINK } = gbCommonInfo
export default {
  name: 'ErrorPage',
  components: {
    SFeedback: Feedback
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      IMG_LINK
    }
  },
  methods: {
    reRequest () {
      this.$emit('reRequest')
    }
  }
}
</script>
<style lang="less" scoped>
  .recommend__empty-record-list{
    padding: 200/75rem 0;
    .recommend__empty-record-icon{
      width: 174/75rem;
      height: 139/75rem;
      margin-bottom: 0.64rem;
      border: none;
    }
    .recommend__empty-record-sub{
      margin-bottom: 0.32rem;
      font-family: 'SF UI Text';
      font-style: normal;
      font-weight: 700;
      .font-dpr(28px);
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.003px;
      color: #222;
      text-align: center;
    }
    .recommend__empty-record-desc{
      margin-bottom: 80/75rem;
      font-family: 'SF UI Display';
      font-style: normal;
      font-weight: 400;
      .font-dpr(24px);
      line-height: 17px;
      color: #222;
      text-align: center;
    }
    .recommend__empty-record-btn{
      display: flex;
      justify-content: center;
    }
  }
</style>
