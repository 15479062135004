<script lang="jsx">
import ListSkeletonV2 from 'public/src/pages/product_list_v2/components/ListSkeletonV2/index.vue'

export default {
  name: 'RecommendGoodsHolderTowRow',
  functional: true,
  components: { ListSkeletonV2 },
  props: {
    newCard: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, data }) {

    const page = props.newCard ? 'RecommendCommonNewCardTowRow' : 'RecommendCommonOldCardTowRow'

    return (
      <div 
        class={[
          'recommend-goods-holder-tow-row',
          data.class,
          data.staticClass,
        ]}
        style={[data.style, data.staticStyle]}
      >
        <ListSkeletonV2 page={page} />
      </div>
    )
  }
}

</script>

